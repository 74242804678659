$(document).ready(function(){
    console.info('Ready!');

  	smoothScroll.init();

  $('.datepicker').datepicker(); 
   
  $('.navbar-trigger, .main-mobile-nav a').on('click', function() {
      $('.mobile-navbar').fadeToggle();
   });

    $('.admin-edit-toggler').click(function() {
        $('.admin-menu').slideToggle(600);
        if($('.admin-editbox').is(':visible'))
            $('.admin-editbox').fadeOut(600); 
    });
 
    $('.autosubmit').change(function() {
      $(this).parents('form').submit();
    })

});

function toggleModal(selector) {
	$(".modal").modal('hide');
	$(selector).modal('show');
}

function changeFormSiteAdmin(contentType) {
    $('.admin-editbox').fadeOut();
    $('.editbox-' + contentType).fadeIn();
}

function toggleFormList(contentID) {
	$('.admin-form-list').hide();
	$('.form-list-' + contentID).fadeIn();
}