app.controller('GiftController', ['$scope','$rootScope','$sce', '$http','validateForm', function($scope,$rootScope, $sce, $http, validateForm) {
    $scope.cart = [];

    $scope.removeFromCart = function(giftID) {
        $http.post('/cart/remove', JSON.stringify({'id': giftID}))
        .then(function(response) {
            $scope.cart = angular.copy(response.data.cart);
            $('.cart-counter').text(response.data.cartNr);         
        }, function(errors) { 
            console.log(errors);
        });
    };

    $scope.addToCart = function(giftID) {
        $http.post('/cart/add', JSON.stringify({'id': giftID}))
        .then(function(response) {
            $scope.cart = angular.copy(response.data.cart);
            $('.cart-counter').text(response.data.cartNr);
        }, function(errors) {
            console.log(errors);
        });
    };

    $scope.getGifts = function() {
        $http.get('/gifts').then(function(response) {
            $scope.gifts = response.data.gifts;
            $scope.cart = response.data.cart;
        },function(errors) {
            console.log(errors);
        });
    }

    $scope.isGiftInCart = function(giftID) {
        var isInCart = false;
        $.each($scope.cart, function(key,itemID) {
            if (itemID == giftID)
                isInCart = true;
        });
        return isInCart;
    }

    $scope.getGifts();

}]);
