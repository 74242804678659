angular.module('formValidator', [])
.factory('validateForm',function() {
   return function(errors,formName) {
        $.each(errors.data, function(index, value)
        {
            if (value.length != 0)
            {
                $(formName + ' input[name="'+ index + '"]').addClass('input-has-error');
                $(formName + ' select[name="'+ index + '"]').addClass('input-has-error');
                $(formName + ' textarea[name="'+ index + '"]').addClass('input-has-error');
            }
        });
    };
});