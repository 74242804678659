app.directive('touchspin',['$http', '$timeout',function($http, $timeout){
    return {
        restrict:'AE',
        scope: {},
        require:"ngModel",
        templateUrl: 'touchspin',

        link: function(scope, element, attr, ngModel) {
            scope.initTouchSpin = function(){
                var spin = $(element).find('.spin');
                $(spin).find("input").TouchSpin();
                
                if(attr.disabled) {
                    $(spin).find("input").attr('disabled','disabled');
                }

                $(spin).find("input").on("change",function() {                   
                    sum = 0;
                    $.each($('.spin').find('input'), function(i,e) {
                        sum += parseFloat($(e).val());                  
                    });

                    maxValue = attr.max - (sum - parseFloat($(this).val()));
                    
                    if( $(this).val() > maxValue )
                        $(this).val(maxValue.toFixed(2));

                    ngModel.$setViewValue($(this).val());
                });

            }

            scope.initTouchSpin();    

        }
    }
}]);