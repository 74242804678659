app.controller('SiteAdminController', ['$scope','$rootScope','$sce', '$http','validateForm', function($scope,$rootScope, $sce, $http, validateForm) {
    $('[adminable]').hover(function() {
        $(this).css('opacity','0.4');
        $(this).css('cursor','pointer');
    }, function() {
        $(this).css('opacity','1');
    });
   

    $scope.showContentModal = function(contentID) {
        $http.get('/dashboard/page-contents/' + contentID)
        .then(function(response) {
            $scope.contentID = contentID;
            $scope.fields = response.data.fields;
            $('#contentForm').modal('show');
        }, function(errors) { 
            console.log(errors);
        });
    };
 
    $scope.showQuestionModal = function(questionID) {
        $http.get('/dashboard/questions/' + questionID + '/ajax')
        .then(function(response) { 
            $scope.questionID = questionID;
            $scope.question =response.data.question;
            $scope.options = response.data.options;
            $('#questionForm').modal('show');
        }, function(errors) {  
            console.log(errors);
        });
    };

    $scope.showQuestionSheetModal = function(sheetID) {
        $http.get('/dashboard/questions/sheet/' + sheetID + '/ajax')
        .then(function(response) { 
            $scope.sheet = response.data.sheet;
            console.log($scope.sheet);
            $('#questionSheetForm').modal('show');
        }, function(errors) {  
            console.log(errors);
        });
    };

    $scope.showArticleModal = function(articleID) {
        $http.get('/dashboard/articles/' + articleID + '/ajax')
        .then(function(response) { 
            $scope.articleID = articleID;
            $scope.article =response.data.article; 
            tinymce.get("#description").setContent('<span>some</span> html');
            $('#articleForm').modal('show');
        }, function(errors) {  
            console.log(errors);
        });
    };

    $scope.deleteQuestion = function(questionID) {
        $http.post('/dashboard/questions/delete/' + questionID + '/ajax')
        .then(function(response) { 
            window.location.href = window.location.href;
        }, function(errors) { 
        });
    };

}]);