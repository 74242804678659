app.controller('InviteController', ['$scope','$rootScope','$sce', '$http','validateForm', function($scope,$rootScope, $sce, $http, validateForm) {
    $scope.formData = {};
    $scope.mavInviteNumber = 5;
	$scope.formData.invites = [ {'name': null,'email':null} ];

    $scope.setMessage = function(user,message) {
        console.log(message);
    }

    $scope.removeInputGroup = function() {
        $scope.formData.invites.splice(this.key,1);
    };

    $scope.addInputGroup = function() {
        if ($scope.formData.invites.length < $scope.mavInviteNumber )
        $scope.formData.invites.push( {'name': null,'email':null} );
    };

    $scope.submitInvites = function() {
        $http.post('/invite/friends', angular.toJson( $scope.formData )).then(function(data) {
            window.location.href = "/invite-success";
            
        }, function(errors) {
            validateForm(errors,'.invite-form'); 
        });
    }
}]);
