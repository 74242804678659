app.controller('BetController', ['$scope','$rootScope','$sce', '$http','validateForm', function($scope,$rootScope, $sce, $http, validateForm) {
    $scope.results = {
        1 : 'Home',
        2 : 'Draw',
        3 : 'Away'
    };
    $scope.betItems = [];

    $scope.removeFromItems = function(itemID) {
        $.each($scope.betItems, function(key,item) {
            if(typeof item != "undefined" && item.id == itemID)
                $scope.betItems.splice(key,1);
        });
    };

    $scope.addToItems = function(itemID,resultID) {
        if( $scope.isInItems(itemID,resultID) )
            return;

        $http.post('/bet/add-item', JSON.stringify({'id': itemID,'result_id':resultID}))
        .then(function(response) {
        
        item = response.data.item;

        isInItems = false;

        $.each($scope.betItems, function(key,betItem) {
            if(betItem.id == itemID) {
                $scope.betItems[key] = item;   
                isInItems = true;                
            }

        });

       if(!isInItems)
            $scope.betItems.push(item);

        }, function(errors) {
            console.log(errors);
        });
    };

    $scope.getWinnings = function(stake,multiplier,key) {
        result = 0;

        if(!isNaN(parseFloat(stake)) && multiplier) 
           result = stake * multiplier;
        
        $scope.betItems[key].winnings = result > 0 ? result.toFixed(2) : 0.00;
    }

    $scope.getBalance = function() {
        sum = 0;
        $.each($scope.betItems, function(key,item) {
            sum += !isNaN(parseFloat(item.stake)) ? parseFloat(item.stake) : 0; 
        });
        result = ($scope.balance - sum.toFixed(2)).toFixed(2);
        return result;
    }

     $scope.isInItems = function(betItemID,resultID) {
        var isInItems = false;
        $.each($scope.betItems, function(key,item) {
            if (item.id == betItemID && item.result_id == resultID)
                isInItems = true;
        });

        return isInItems;
    }

    $scope.backToSheet = function() {
        $('.confirm-sheet-slips').hide();  
        $('.sheet-table').show();
        $('.mobil-sheet-table').removeClass('hidden-xs');
        $('.tipp-info').show();
        $('.sheet-slips').show();
    }

    $scope.submitForm = function() {
    $http.post('/bet', angular.toJson({ 'bet_sheet_id': $scope.betSheet.id, 'bet_items': $scope.betItems}))
        .then(function(response) {
            window.location.href = "/bet/success";

        }, function(response) {
            $scope.errors = [];
            $scope.errors.push(response.data.error);
            $('#error-popup').modal();
            
            return;
        });

    }

    $scope.sendToConfirm = function() {
        $scope.errors = [];
        $scope.itemsHasStake = [];

        $.each($scope.betItems, function(key,item) {
            console.log(item.stake);
            if(item.stake > 0) {
                $scope.itemsHasStake.push(item);
            }
        });

        if($scope.itemsHasStake.length < 3)
            $scope.errors.push($scope.errorMessages['min_bet_eventnumber']['message']);

        if($scope.getBalance() != 0.00) {
            $scope.errors.push($scope.errorMessages['balance_not_null']['message']);
        }

        if($scope.errors.length > 0) {
            $('#error-popup').modal();
            return;
        }

        $.each($scope.betItems, function(key,item) {
            if(item.stake <= 0 || !item.stake)
                $scope.betItems.splice(key,1);
        });

        $('.sheet-table').hide();
        $('.mobil-sheet-table').addClass('hidden-xs');
        $('.tipp-info').hide();
        $('.sheet-slips').hide();
        $('.confirm-sheet-slips').show();
    }

}]);
